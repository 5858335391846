import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from '../components/Sidebar';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Layout = ({ children }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const theme = useTheme();

  const handleDrawerToggle = (isOpen) => {
    setDrawerOpen(isOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar onDrawerToggle={handleDrawerToggle} isDrawerOpen={isDrawerOpen} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          transition: 'margin 0.3s ease',
          marginLeft: isDrawerOpen ? `${drawerWidth}px` : `calc(${theme.spacing(7)} + 1px)`,
          [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
          },
        }}
      >

        {children}
      </Box>
    </Box>
  );
};

export default Layout;
