// src/pages/Report.jsx
import React from 'react';
import Layout from './Layout';
import Typography from '@mui/material/Typography';

const Report = () => {
  return (
    <Layout>
      <Typography variant="h4">Report</Typography>
      <Typography paragraph>This is the Report page content.</Typography>
    </Layout>
  );
};

export default Report;
