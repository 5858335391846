// src/pages/Setting.jsx
import React from 'react';
import Layout from './Layout';
import Typography from '@mui/material/Typography';

const Setting = () => {
  return (
    <Layout>
      <Typography variant="h4">Setting</Typography>
      <Typography paragraph>This is the Setting page content.</Typography>
    </Layout>
  );
};

export default Setting;
