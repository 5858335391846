// src/routes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Request from './pages/Request';
import Report from './pages/Report';
import Notification from './pages/Notification';
import Setting from './pages/Setting';
// import Logout from './pages/Logout';
import Account from './pages/Account';
import Help from './pages/Help';
import User from './pages/User';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Dashboard />} />
    <Route path="/request" element={<Request />} />
    <Route path="/report" element={<Report />} />
    <Route path="/notification" element={<Notification />} />
    <Route path="/setting" element={<Setting />} />
    <Route path="/user" element={<User />} />
    <Route path="/account" element={<Account />} />
    <Route path="/help" element={<Help />} />
  </Routes>
);

export default AppRoutes;
