// src/pages/Login.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Alert, Image, Row, Col } from 'react-bootstrap';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === 'user' && password === 'pass') {
      localStorage.setItem('isAuthenticated', 'true');
      onLogin();
      navigate('/');
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <Container fluid className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
      <Form onSubmit={handleSubmit} style={{ maxWidth: '400px', width: '100%', textAlign: 'center' }}>
        <Image src="/images/gated_logo.png" fluid style={{ marginBottom: '20px' }} />
        {error && <Alert variant="danger" style={{ marginBottom: '20px' }}>{error}</Alert>}
        <Form.Group controlId="formBasicEmail" className="mb-4">
          <Form.Control
            type="text"
            placeholder="Username or Email Address"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword" className="mb-4">
          <Form.Control
            type="password"
            placeholder="Enter your Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Row className="mb-4">
          <Col className="d-flex justify-content-start">
            <Form.Check type="checkbox" label="Remember me" />
          </Col>
          <Col className="d-flex justify-content-end">
            <a href="#" style={{ textDecoration: 'none' }}>Forgot Password?</a>
          </Col>
        </Row>
        <Button variant="primary" type="submit" className="w-100 mb-4" style={{borderRadius:"50px"}}>
          Log in
        </Button>
        <div className="d-flex justify-content-center">
          <span>Not a member? <a href="#" style={{ textDecoration: 'none' }}>Sign up</a></span>
        </div>
      </Form>
    </Container>
  );
};

export default Login;
