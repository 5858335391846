// src/pages/User.jsx
import React from 'react';
import Layout from './Layout';
import Typography from '@mui/material/Typography';

const User = () => {
  return (
    <Layout>
      <Typography variant="h4">User</Typography>
      <Typography paragraph>This is the User page content.</Typography>
    </Layout>
  );
};

export default User;
