// src/pages/Help.jsx
import React from 'react';
import Layout from './Layout';
import Typography from '@mui/material/Typography';

const Help = () => {
  return (
    <Layout>
      <Typography variant="h4">Help</Typography>
      <Typography paragraph>This is the Help page content.</Typography>
    </Layout>
  );
};

export default Help;
