// src/pages/Notification.jsx
import React from 'react';
import Layout from './Layout';
import Typography from '@mui/material/Typography';

const Notification = () => {
  return (
    <Layout>
      <Typography variant="h4">Notification</Typography>
      <Typography paragraph>This is the Notification page content.</Typography>
    </Layout>
  );
};

export default Notification;
