// src/pages/Dashboard.jsx
import React from 'react';
import Layout from './Layout';
import Typography from '@mui/material/Typography';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import { formatDate } from '../utils/dateUtils';
import { Avatar } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { getCurrentDate } from './utils/dateUtils';

const Dashboard = () => {
    const currentDate = formatDate(); 


  return (
    <Layout>

        <Container className='d-flex flex-column gap-5' >
<Row className="align-items-center gap-4" style={{height:"100%"}}>
        <Col xs="6">
          <Image src="/images/gated_logo.png" fluid />
        </Col>
        <Col xs="5" className='align-items-center justify-content-center d-flex'>
          <Typography variant="h6" paragraph>
            {currentDate}
          </Typography>
        </Col>
      </Row>

      <Row> <Col xs="6" className='align-items-center justify-content-center d-flex'>
      <Typography variant="h4" paragraph style={{fontWeight:600,margin:"0px"}}>
            Dashboard Management
          </Typography>
          </Col>
          
           <Col xs="5" className='align-items-center justify-content-center d-flex'>
          <Button style={{backgroundColor:"#4f46c7",borderRadius:"50px", padding:"10px 40px"}}>
          <Typography variant="h6" paragraph style={{margin:"0px"}}>
            View Requests
          </Typography></Button>
          </Col>
          </Row>
      <Row style={{height:"200px"}}>
      <Col xs="6" className='align-items-center justify-content-center d-flex'>
      <Card style={{ width: '100%', height:"100%",borderRadius:"15px" }}>
      <Card.Body  className='align-items-center justify-content-center d-flex flex-column gap-3'>
      <Typography variant="h6" paragraph style={{margin:"0px",textAlign:"center"}}>
            Registered Residents
          </Typography>

          
          <Avatar sx={{ bgcolor: "#4f46c7", width: 125, height: 125 }}>
      <Typography variant="h3" paragraph style={{ margin: "0px", color: "white", textAlign: "center",fontWeight:600 }}>
        24
      </Typography>
    </Avatar>

      </Card.Body>
      </Card>
      </Col>
      <Col xs="5" className='align-items-center justify-content-center d-flex'>
      
      <Card style={{ width: '100%', height:"100%",borderRadius:"15px", backgroundColor:"#4f46c7" }}>
      <Card.Body  className='align-items-center justify-content-center d-flex flex-column gap-3'>
      <Typography variant="h6" paragraph style={{margin:"0px",textAlign:"center",color:"white"}}>
            Pending Requests
          </Typography>

          
          <Avatar sx={{ bgcolor: "white", width: 125, height: 125 }}>
      <Typography variant="h3" paragraph style={{ margin: "0px", color: "#4f46c7", textAlign: "center",fontWeight:600 }}>
        5
      </Typography>
    </Avatar>

      </Card.Body>
      </Card>

      </Col></Row>
      <Row style={{height:"150px"}}>
      <Col xs="6" className='align-items-center justify-content-center d-flex'>
      <Card style={{ width: '100%', height:"100%",borderRadius:"15px" }}>
      <Card.Body >

<div className='d-flex justify-content-between'>
<Typography variant="h6" paragraph style={{ margin: "0px", fontSize:"18px" }}>
        Recent Requests
      </Typography>

      <span className='d-flex align-items-center' style={{margin:"0px"}}><p>
        <span>See Details</span>
        <span><ArrowDropDownIcon/></span>
        </p></span>
</div>
<div>
<Typography variant="h4" paragraph style={{ margin: "0px", fontWeight:"600" }}>
       John Doe
      </Typography>

</div>
<div><p>Lot 56, West Trade Way</p></div>
          


      </Card.Body>
      </Card>
      </Col>
        
        <Col>
        <Image src="/images/smart_logo.png" fluid />
        </Col></Row>
      </Container>
    </Layout>
  );
};

export default Dashboard;
