// src/pages/Request.jsx
import React from 'react';
import Layout from './Layout';
import Typography from '@mui/material/Typography';

const Request = () => {
  return (
    <Layout>
      <Typography variant="h4">Request</Typography>
      <Typography paragraph>This is the Request page content.</Typography>
    </Layout>
  );
};

export default Request;
