// src/components/Sidebar.jsx
import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import SpeedIcon from '@mui/icons-material/Speed';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#000',
  color: '#fff',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: '#000',
  color: '#fff',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  backgroundColor: '#000',
  color: '#fff',
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: 'fixed',
    zIndex: 1200,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    }),
  }),
);

export default function Sidebar({ onDrawerToggle }) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 799);

  const handleDrawerOpen = () => {
    setOpen(true);
    onDrawerToggle(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    onDrawerToggle(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 799;
      setIsMobile(mobile);
      if (mobile) {
        setOpen(false);
        onDrawerToggle(false);
      } else {
        setOpen(true);
        onDrawerToggle(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [onDrawerToggle]);

  const icons = [
    { text: 'Dashboard', icon: <SpeedIcon />, route: '/' },
    { text: 'Request', icon: <TrendingUpIcon />, route: '/request' },
    { text: 'Report', icon: <ArticleIcon />, route: '/report' },
    { text: 'Notification', icon: <NotificationsIcon />, route: '/notification' },
    { text: 'Setting', icon: <SettingsIcon />, route: '/setting' },
    { text: 'Account', icon: <AccountCircleIcon />, route: '/account' },
    { text: 'User', icon: <PeopleIcon />, route: '/user' },
    { text: 'Help', icon: <HelpIcon />, route: '/help' },
  ];

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <Typography
          variant="h4"
          sx={{
            px: 2.5,
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            ...(!open && { display: 'none' }),
          }}
        >
          Security Post
        </Typography>
        {isMobile && (
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              color: '#fff',
              ...(!open && { display: 'none' }),
            }}
          >
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        )}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {icons.map(({ text, icon, route }) => (
          <ListItem key={text} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              component={NavLink}
              to={route}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                '&:hover': {
                  backgroundColor: '#555',
                },
                '&.active': {
                  backgroundColor: '#333',
                  color: '#fff',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: '#fff',
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0, color: '#fff' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ my: 2 }} />
    </Drawer>
  );
}
